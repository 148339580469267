import React, { useState, useEffect } from 'react'
import {
  Grid,
  TextField,
  IconButton,
  Tooltip,
  Button,
  Typography,
  Paper,
  Box,
  Container
} from '@material-ui/core'
import LambdaFetch from '../functions/FetchFromLambda'
import LoadingButton from '../components/LoadingButton'
import { ContentCopy } from '@mui/icons-material'

export default function UserAccount (props) {
  const [state, setstate] = useState({
    isLoading: false
  })
  const [credentials, setCredentials] = useState({
    host: '',
    port: '',
    username: '',
    password: ''
  })
  const [copiedField, setCopiedField] = useState('')

  const fetchData = async () => {
    try {
      console.log(props)
      //   const resp = await LambdaFetch(
      //     'admin',
      //     'post',
      //     props.fetchInitalData.credentials.user.accessToken,
      //     JSON.stringify({
      //       action: 'create_db_user'
      //     }),
      //     '',
      //     credentials
      //   )
      setCredentials({
        host: 'apix-dev-1.cpi0iika00k3.us-east-1.rds.amazonaws.com',
        port: '3306',
        username: 'testuser1234',
        password: '1fl2rf@F23r3$g5'
      })
    } catch (e) {
      console.log(e)
    }
  }
  const handleCopy = (text, field) => {
    navigator.clipboard.writeText(text)
    setCopiedField(field)
    setTimeout(() => setCopiedField(''), 2000) // Clear copied message after 2 seconds
  }

  return (
    <Container maxWidth='sm' style={{ marginTop: '2rem' }}>
      <Typography variant='h6' gutterBottom>
        Database Access Credentials
      </Typography>
      <LoadingButton
        label='Submit'
        isLoading={state.isLoading}
        color='primaryVLButton'
        fn={() => fetchData()}
      />

      {credentials.username && credentials.password && (
        <Paper elevation={3} style={{ marginTop: '1rem', padding: '1rem' }}>
    
          {Object.keys(credentials).map(cur => {
            return (
              <>
                <Box display='flex' alignItems='center' marginTop={1}>
                  <TextField
                    label={cur}
                    variant='outlined'
                    fullWidth
                    size='small'
                    value={credentials[cur]}
                    InputProps={{
                      readOnly: true
                    }}
                    style={{ marginRight: '0.5rem' }}
                  />
                  <Tooltip title='Copy Password'>
                    <IconButton
                      onClick={() =>
                        handleCopy(credentials[cur], cur)
                      }
                      aria-label={`copy ${cur}`}
                      color='primary'
                    >
                      <ContentCopy />
                    </IconButton>
                  </Tooltip>
                </Box>
                {copiedField === cur && (
                  <Typography
                    variant='body2'
                    color='success.main'
                    style={{ marginTop: '0.5rem' }}
                  >
                    {`${cur} copied to clipboard!`}
                  </Typography>
                )}
              </>
            )
          })}
        </Paper>
      )}
    </Container>
  )
}
